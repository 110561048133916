// PASSPORT
export { default as Browser } from "./Browser.vue";
export { default as Failure } from "./Failure.vue";
// LICENCE
export { default as NZLLicence } from "./Licence/NZLLicence.vue";
export { default as AUSLicence } from "./Licence/AUSLicence.vue";
// MEDICARE
export { default as MedicareBlue } from "./MedicareBlue.vue";
export { default as MedicareGreen } from "./MedicareGreen.vue";
export { default as MedicareYellow } from "./MedicareYellow.vue";
// NATIONAL ID
export { default as IndoNationalId } from "./NationalId/IndoNationalId.vue";
export { default as SingaporeNationalIdName } from "./NationalId/SingaporeNationalIdName.vue";
export { default as SingaporeNationald } from "./NationalId/SingaporeNationald.vue";
export { default as ThaiNationalIdBack } from "./NationalId/ThaiNationalIdBack.vue";
export { default as ThaiNationalIdFront } from "./NationalId/ThaiNationalIdFront.vue";
export { default as ThaiNationalIdFrontEnglishName } from "./NationalId/ThaiNationalIdFrontEnglishName.vue";
export { default as ThaiNationalIdFrontName } from "./NationalId/ThaiNationalIdFrontName.vue";
export { default as AustralianPassport } from "./Passport/AustralianPassport.vue";
export { default as ForeignPassport } from "./Passport/ForeignPassport.vue";
export { default as NewZealandPassport } from "./Passport/NewZealandPassport.vue";
